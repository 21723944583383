@import '~antd/dist/antd.less';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Custom color for basic design
// @menu-color: #343a40;
// @menu-color-active: #636363;
// @primary-color-bd: #0085fc;

// // Custom theme for antd: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// @primary-color: @primary-color-bd;
// @menu-dark-bg: @menu-color;
// @menu-dark-inline-submenu-bg: @menu-color;
// @menu-dark-item-active-bg: @menu-color-active;
// @layout-sider-background: @menu-color;
// @card-head-background: #f0f1f2;


:root{
  // value global
  --app-header-h:30px;
  --app-footer-h:48px;
  --app-page-content-h:calc(100vh - var(--app-footer-h));
}


* {
  box-sizing: border-box ;
  padding: 0;
  margin: 0;
  font-weight:  400;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url(../fonts/Pretendard-Regular.otf) format('otf'), url(../fonts/PretendardVariable.ttf) format('truetype');
}

body {
  
  font-family: 'pretendard-regular', sans-serif;
  text-rendering: optimizeSpeed;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
}
//
.custom-segmented{
  border-radius: 8px !important;


  .ant-segmented-item-label {
    padding: 0 !important;
    border-radius: 8px;

  }
  .ant-segmented-item {
    border-radius: 8px;
  }
}
//css overwrite table

.custom-table-antd{
  overflow: hidden;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  // max-height: 80vh;
  .ant-table-thead .ant-table-cell{
    padding: 8px 4px !important;
    height: 40px !important;
    text-transform: capitalize;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 0px 0px !important;
    height: 36px !important;
    font-size: 12px;
    font-weight:  500;
  }
 
}

.custom-table-antd.table-hotel-result  .ant-table-body{
  height: calc(100vh - 68px - 100px  - 48px - 80px );
}

.custom-table-antd.table-mapping{
  height: calc(100vh - 68px - 100px);

}

.ant-layout-content {
  gap: 8px;
  flex: auto;
  min-height: auto;
}
.custom-select .ant-select-selector {
  //border-radius: 8px !important;
  outline: none;
}
.highlight{
  background-color: yellow;
  font-weight: bold;
}

.custom-select-meta-data {
  align-items: flex-start;
}

/* Grid Column */
.square-button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  color: #555555;
}
.button-icon {
  font-size: 15px;
}

.button-icon:hover {
  background-color: rgb(230,233,235);
}